#root {
  text-align: center;
}

.hv-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

button:focus {
  outline: none;
}
